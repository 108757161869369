import { useOrdersCountStore } from '~/stores/ordersCount.store';
import { client } from '~/utils/axiosClient.util';
import type {
  IOrderResponse,
  IOrdersListResponse,
  PrescriptionPdfUrlResponse,
  IAcceptOrder,
  Order,
  InvoicePdfUrlResponse,
  OrderType, DpdLabelPdfResponse,
} from '~/types';
import { OrderEvents } from '~/enums/orderEvent.enum';

export async function loadOrders(
  status: string = OrderEvents.PENDING,
  pagination: { limit: number; offset: number } = { limit: 20, offset: 0 },
  searchWord?: string,
  byMedication?: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  try {
    let queryParams = `?status=${status}`;
    if (searchWord) {
      queryParams += `&searchWord=${searchWord}`;
      if (byMedication) {
        queryParams += `&byMedication=${byMedication}`;
      }
    }
    if (pagination) {
      queryParams += `&limit=${pagination.limit}&offset=${pagination.offset}`;
    }
    const response = await client.get<IOrdersListResponse>(
      `/orders${queryParams}`,
    );
    return response.data.orders;
  }
  catch (err: unknown) {
    console.error('An error occurred while loading orders. ', err);
  }
}

/**
 * Only loaded for notification sound
 */
export async function loadPendingOrders(): Promise<void> {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await client.get<any>(`/orders/count`);
    const pendingOrdersCount: number = response.data?.count?.pendingOrders;
    useOrdersCountStore().storePendingOrdersCount(pendingOrdersCount);
  }
  catch (err: unknown) {
    console.error('An error occurred while loading pending orders. ', err);
  }
}

export async function loadOrder(id: string): Promise<Order> {
  const { order } = await client.get<IOrderResponse>(`/orders/${id}`);
  return order;
}

export async function loadPrescriptionPdfUrl(id: number): Promise<string> {
  const res = await client.get<PrescriptionPdfUrlResponse>(
    `/orders/${id}/pdf_url`,
  );
  return res.data.url;
}

export async function loadInvoicePdfUrl(id: number): Promise<string> {
  const res = await client.get<InvoicePdfUrlResponse>(
    `/orders/${id}/invoice_url`,
  );
  return res.data.url;
}

export async function loadPrescriptionPinkSheetUrl(
  id: number,
): Promise<string> {
  const res = await client.get<PrescriptionPdfUrlResponse>(
    `/orders/${id}/pink_sheet_url`,
  );
  return res.data.url;
}

export async function acceptOrder(
  orderId: number,
  orderType: OrderType,
  payload?: IAcceptOrder,
): Promise<void> {
  const requestBody = payload
    ? {
        ...payload,
        order_type: orderType,
        status: OrderEvents.PHARMACY_ACCEPTED,
      }
    : {
        order_type: orderType,
        status: OrderEvents.PHARMACY_ACCEPTED,
      };
  await client.patch(`/orders/${orderId}`, requestBody);
  loadPendingOrders();
}

export async function requestChanges(
  orderId: number,
  orderType: OrderType,
  message: string,
) {
  await client.patch(`/orders/${orderId}`, {
    status: OrderEvents.CHANGES_REQUESTED,
    order_type: orderType,
    message,
  });
}

// Ready for pickup
export async function affectDeliveryJob(orderId: number): Promise<void> {
  await client.post(`/orders/delivery_service/${orderId}`, {});
}

export async function updateFP10CDFReceived(
  orderId: number,
  orderType: OrderType,
) {
  await client.patch(`/orders/${orderId}`, {
    status: OrderEvents.CDF_ARRIVED,
    order_type: orderType,
  });
}

export async function updateMedicationsReceived(
  orderId: number,
  orderType: OrderType,
) {
  await client.patch(`/orders/${orderId}`, {
    status: OrderEvents.MEDICATIONS_RECEIVED,
    order_type: orderType,
  });
}

export async function updateReturnReceived(deliveryJobId: number) {
  await client.patch(`/delivery_job/${deliveryJobId}/returned`, {});
}

export async function loadDpdLabel(order_id: number): Promise<string> {
  const res = await client
    .get<DpdLabelPdfResponse>(`/orders/${order_id}/shipping-label`);

  return res.data.label;
}

export async function updateOrderPacked(
  orderId: number,
  orderType: OrderType,
) {
  await client.patch(`/orders/${orderId}`, {
    status: OrderEvents.PACKED,
    order_type: orderType,
  });
}


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index59OgeahOk2Meta } from "/vercel/path0/pages/delivery-schedules/index.vue?macro=true";
import { default as indexBEnub4EOtuMeta } from "/vercel/path0/pages/dispense/index.vue?macro=true";
import { default as indexaGlvcu5ussMeta } from "/vercel/path0/pages/dispense/prescription/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as invoicesOsO5xW78nEMeta } from "/vercel/path0/pages/invoices.vue?macro=true";
import { default as indexMzbDRHMBrDMeta } from "/vercel/path0/pages/orders/[id]/index.vue?macro=true";
import { default as indexan9ZrZAr6jMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as signin0XCmIKo45IMeta } from "/vercel/path0/pages/signin.vue?macro=true";
export default [
  {
    name: "delivery-schedules",
    path: "/delivery-schedules",
    component: () => import("/vercel/path0/pages/delivery-schedules/index.vue")
  },
  {
    name: "dispense",
    path: "/dispense",
    meta: indexBEnub4EOtuMeta || {},
    component: () => import("/vercel/path0/pages/dispense/index.vue")
  },
  {
    name: "dispense-prescription",
    path: "/dispense/prescription",
    meta: indexaGlvcu5ussMeta || {},
    component: () => import("/vercel/path0/pages/dispense/prescription/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/vercel/path0/pages/invoices.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/vercel/path0/pages/orders/[id]/index.vue")
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/vercel/path0/pages/orders/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path0/pages/settings.vue")
  },
  {
    name: "signin",
    path: "/signin",
    meta: signin0XCmIKo45IMeta || {},
    component: () => import("/vercel/path0/pages/signin.vue")
  }
]